<template lang="pug">
.page.page-with-table.points-page
  .points-page__search
    it-input.points-page__search-input(v-model="points" @keydown.enter="search")
    it-button.points-page__search-btn(type="primary" :loading="loading" @click="search") Найти

  data-table.page__table.points-page__table(:headers="headers" :items="data" :loading="loading" :actions="false")
    template(#actions="{ item }")
      it-button(type="warning" outlined @click="copyItem(item)") Копировать
    template(#customCell="{ item }")
      it-input.points-page__table-custom-cell(v-model="item.customCell")


  it-button.points-page__copy-action(v-if="data.length > 0" type="warning" @click="copyAllItems") Копировать
  .points-page__empty(v-show="notFound") Ничего не найдено
</template>

<style lang="sass" src="./index.sass"></style>


<script>
import DataTable from '@/components/DataTable'


export default {
  name: 'PointsPage',
  components: {DataTable},
  data() {
    return {

      loading: false,
      points: '',
      data: [],
      notFound: false

    }
  },
  computed: {
    queryPoints() {
      return this.$route.query.points
    },
    headers() {
      const headers =  [
        {title: 'Действия', value: 'actions'},
        {title: "RCS_NUM", value: 'exid'},
        {title: 'RCS', value: 'exid'},
        {title: 'Наименование', value: 'fname'},
        {title: 'Сокр. наименование', value: 'fshortname'},
        {title: 'Адрес', value: 'faddress'},
        {title: 'Ориентир', value: 'fcomment'},
        {title: 'Контактное лицо', value: 'fPersone'},
        {title: 'Телефон', value: 'fPhone'},
        {title: 'Площадка', value: 'distname'},
        {title: 'Region', value: 'Region'},
        {title: 'Area Code', value: 'Area Code'},
        {title: 'Territory code', value: 'Territory code'},
        {title: 'Канал сбыта', value: 'Канал сбыта'},
        {title: 'Класс объема', value: 'Класс объема'},
        {title: 'Сегмент', value: 'Сегмент'},
        {title: 'Город Узбекистана', value: 'Город Узбекистана'},
        {title: 'Код DR', value: 'Код DR'},
        {title: 'Код TMR', value: 'Код TMR'},
        {title: 'Форма собственности', value: 'Форма собственности'},
        {title: 'Торговое оборудование №1', value: 'Торговое оборудование №1'},
        {title: 'Количество торг. оборудования №1', value: 'Количество торг. оборудования №1'},
        {title: 'Торговое оборудование №2', value: 'Торговое оборудование №2'},
        {title: 'Количество торг. оборудования №2', value: 'Количество торг. оборудования №2'},
        {title: 'Торговое оборудование №3', value: 'Торговое оборудование №3'},
        {title: 'Количество торг. оборудования №3', value: 'Количество торг. оборудования №3'},
        {title: 'Торговое оборудование №4', value: 'Торговое оборудование №4'},
        {title: 'Количество торг. оборудования №4', value: 'Количество торг. оборудования №4'},
        {title: 'Программа TM', value: 'Программа TM'},
        {title: 'Район города', value: 'Район города'},
        {title: 'В радиусе 500 метров:', value: 'В радиусе 500 метров:'},
        {title: 'Отдаленность (TCL)', value: 'Отдаленность (TCL)'},
        {title: 'Канал', value: 'Канал'},
        {title: 'Exclusive', value: 'Exclusive'},
        {title: 'Менеджер', value: 'mrname'},
        {title: 'Custom', value: 'customCell'},
        {title: 'Активность', value: 'active'},
        {title: 'Классификация', value: 'classify'},
        {title: 'Текущая дата', value: 'current_date'},
        {title: 'Дата изменения', value: 'changedate'},
      ]

      headers.forEach( elem => {
        elem.readonly = true
        elem.sortable = true
      })

      return headers
    }
  },


  watch: {
    queryPoints: {
      handler() {
        if ( this.queryPoints ) {
          this.points = decodeURIComponent(this.queryPoints)
          this.getPoints()
        }
      },
      immediate: true
    }
  },
  methods: {
    search() {
      this.$router.push({ path: 'points', query: {points: encodeURIComponent(this.points)} })
    },

    async getPoints() {
      if ( this.loading ) return
      this.loading = true
      this.notFound = false

      try {
        const {data} = await this.$api.get(`/points?points=${this.points}`)
        this.data = data.result.data
        this.data.forEach( elem => {
          elem.customCell = null
          elem.changedate = this.formattingDate(elem.changedate)
          elem.current_date = this.formattingDate() 
        })

        if ( data.result.data.length === 0 ) this.notFound = true
      } catch(err) {
        console.log(err)
      }

      this.loading = false
    },

    copyItem(item) {
      const data = this.__getItemValuesForExcel(item)
      this.$excel.copy([ data ])
    },
    copyAllItems() {
      const data = this.data.map( elem => this.__getItemValuesForExcel(elem) )
      this.$excel.copy(data)
    },


    __getItemValuesForExcel(item) {
      const data = []
      this.headers.forEach( header => {
        if ( header.value !== 'actions' ) data.push( item[header.value] )
      })

      return data
    }
  }
}
</script>